<template>
  <v-container class="rules-list-container">
    <BackToTop></BackToTop>
    <v-row class="">
      <v-col cols="12" class="rules-global-content-container">
        <NavBar></NavBar>
        <v-row>
          <v-col cols="12" lg="12" class="rules-title-name-container">
            Hvala
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="rules-content-container">
              <p>drage moje klijentice,</p>
              <p>
                ne znam otkuda da krenem.<br>
                ne znam je li ovo što radim standardna praksa ili sam ja samo jedna snažna emocija u ljudskom obliku, <br>ali ne mogu si pomoći.<br>
                nisam vam mogla uživo lice u lice izreći što ću sada kroz ovaj tekst jer bi suza bilo na sve strane :')
              </p>
              <p>
                za one koje nemaju koncentraciju da pročitaju, TLDR verzija:<br> hvala na svemu, falit ćete mi, volim vas i dođite na oproštajku - <span class="tldr-link" @click="scrollToBottom">detalji ovdje</span> :)<br>
                no ako vam nije preteško, molim vas da ipak sve pročitate jer želim prenijeti svoje osjećaje i stvarno sam se potrudila u vezi ovog teksta :)
              </p>
              <v-img
              eager
                class="gallery-image thankyou-image"
                :src="require(`@/assets/thankyou/1.jpg`)"
              ></v-img>
              <div class="caption-text">2016.</div>
              <p>
                dakle...<br>
                ima vas koje su sa mnom par mjeseci, a ima vas koje su ovdje godinama.<br>
                neke dolaze i duplo duže nego što Migoto postoji... i stoga poseban shoutout ĐejNails ekipi :)) ali hvala svakoj od vas, koliko god dugo se znamo.<br>
                hvala što ste one najjače koje su opstale, jer ih je toliko prošlo kroz moju malu sobu za nokte s kojima iz nekog razloga nije kliknulo, toliko ih je bilo za koje bih se vezala pa bi mi lomile srce, toliko ih je bilo samo jednom i nikad više... <br>
              </p><v-img
              eager
                class="gallery-image thankyou-image"
                :src="require(`@/assets/thankyou/2.jpg`)"
              ></v-img>
              <div class="caption-text">2016.</div>
              <p>
                ali vi ste ostale i postale moj mali kolektiv.<br>
                hvala vam, od srca... što ste mi vjerovale, što ste cijenile moj rad, što ste dolazile iz mjeseca u mjesec, što ste slušale moje savjete, što ste upile ono bitno u vezi tretmana, što ste me čekale kada je trebalo, što ste razumjele i ostale uz sve promjene cijena, što smo se sve mogle dogovoriti, što ste se naručivale unaprijed ali svejedno bile raspoložive i suradljive za mijenjanje rasporeda kada bi to bilo potrebno meni ili nekoj drugoj klijentici... <br>
                hvala što ste bile preslatke i nosile mi poklone za posebne prigode ili kada ste osjećale da ste me na neki način "zeznule". hvala što ste bile ugodno društvo. <br>
                hvala što ste dijelile djeliće svojih života sa mnom.<br>
                hvala što ste me preporučivale drugim ženama u svojim životima i hvala za svaki instagram story s mojim radom na koji ste me tagale ♡♡♡<br>
                hvala što ste bile moja mala zajednica i na neki način druga obitelj, jer sam vas viđala češće nego vlastite roditelje. ma sve, hvala na svemu. nemam riječi.<br>
              </p>
              <v-img
              eager
                class="gallery-image thankyou-image"
                :src="require(`@/assets/thankyou/3.jpg`)"
              ></v-img>
              <div class="caption-text">2016.</div>
              <p>
                da još jednom rezimiram sve što sam vam pričala i na terminima, već duže vrijeme osjećam potrebu da se maknem od noktiju i istražim neke nove izazove u životu. većinski je zbog fizičkog zdravlja jer sam uvijek u nekim bolovima. već godinama. <br>
                svaki dan bez iznimke me bole leđa i ramena i jedva čekam ispratiti zadnju klijenticu, očistiti radni prostor, sterilizirati pribor i leći na kauč uz neku dobru treš seriju. <br>
                nakon 2-3 sata posla ne vidim više ništa unatoč pomagalima za vid, pa tako često i moja trusty lampa s povećalom gubi smisao.<br>
                šlag na torti su vječno migrirajući bolovi u različitim zglobovima ruku kojima radim i trenutno je na tapeti desna ruka koja sad pati doslovno što god da napravim, pa čak i kada mirujem.<br>
              </p><v-img
              eager
                class="gallery-image thankyou-image"
                :src="require(`@/assets/thankyou/4.jpg`)"
              ></v-img>
              <div class="caption-text">2017.</div>
              <p>
                osim toga, nažalost, ovaj posao me također i psihički iscrpljuje. gotovo stalno osjećam anksioznost iz sto različitih razloga u koje neću sada ulaziti, ali da skratim priču i bolje dočaram situaciju, bilo je dana kad bih svaki termin provela stiskajući zube da se ne rasplačem... a onda bih kasnije plakala mužu na ramenu.<br>
                i intuicija me sad već mjesecima vodi ka tome da se maknem... a čim to toliko dugo traje, ne mogu nastaviti maltretirati samu sebe.<br>
                možda sam o svim ovim problematikama trebala razmišljati prije nego što sam se počela baviti noktima, ali imala sam ružičaste naočale na očima, bila sam jako uzbuđena i jako me usrećivala činjenica da ću raditi - i to nešto toliko zanimljivo i samo, samo moje, iz vlastite radnje.<br>
                ne mogu vam trenutno reći je li ovaj prekid rada samo nekakva pauza ili će biti zauvijek, ali unatoč svemu, volim ovaj posao kao takav i naravno, ako budem mogla i htjela nastaviti, sve do jedne ću vas obavijestiti o tome ♡ <br>
                u međuvremenu... ako trebate prefine i prelijepe kolače po narudžbi za nekakav događaj, ja sam osoba za vas 🧁<br>
              </p>
              <v-img
              eager
                class="gallery-image thankyou-image"
                :src="require(`@/assets/thankyou/5.jpg`)"
              ></v-img>
              <div class="caption-text">2017.</div>
              <p>
                nadalje... hvala vam na lijepim i nesebičnim reakcijama na to što odlazim, hvala što se veselite za mene i shvaćate situaciju i hvala za podršku koju ste pokazale za mene i moje zdravlje, svjesne toga da moram za promjenu staviti sebe na prvo mjesto ♡
              </p>
              <p>
                falit ćete mi. na kraju krajeva, nisam mašina za izradu noktiju, čovjek sam... i znam da puno šutim na terminima.. prvenstveno jer ne znam pričati :D, a također i zato što se koncentriram na posao, pa većina vas vjerojatno ne zna moju istinsku narav. nerado to pokazujem na van, ali sam zapravo jedna jako cendrava, topla, osjetljiva i osjećajna osoba.<br>
                totalni romantik u svakom pogledu, puna ljubavi, i vječno izrazito nostalgična...
              </p>
              <v-img
              eager
                class="gallery-image thankyou-image"
                :src="require(`@/assets/thankyou/6.jpg`)"
              ></v-img>
              <div class="caption-text">2020.</div>
              <p>
                a trenutno si još ne mogu ni zamisliti kako će mi biti tužno i prazno, tako odjednom, da vas više ne viđam svaki mjesec, ali znam sebe i znam da će me pogoditi kao smrtni slučaj ili prekid veze. mislim da će to za mene biti jedan ogroman šok.<br>
                s moje strane, ovo nije zbogom. ovo je "vidimo se" :)<br>
                zato bih voljela da na neki način održimo kontakt. slobodno me dodajte, tko još nije, na privatni instagram - <span style="font-weight:500; color:#f481a6;" @click="openInstagramLink()">@jana__811</span><br>
                pa ćemo se, ako ništa, družiti virtualno :)<br>
                i nemam ništa protiv toga da ponekad odemo na neku kavu ili alkohol. zovite, ne grizem :) <br>
              </p>
              <v-img
              eager
                class="gallery-image thankyou-image"
                :src="require(`@/assets/thankyou/7.jpg`)"
              ></v-img>
              <div class="caption-text">2021.</div>
              <p>
                a u svrhu toga da očuvamo odnose i da proslavimo sve ove divne godine provedene skupa, povežemo se još više i možda sklopimo neka nova prijateljstva, organizirat ću oproštajni party. <br>
                izuzetno bi me radovalo i bila bi mi velika čast da mi se pridružite u što većem broju ♡♡♡ više informacija:
              </p>
              <p>
                🌸 <span style="font-weight:bold; color:#f481a6;" ref="scrollToMe">MIGOTO OPROŠTAJKA</span> 🌸<br>
                <span class="bold-color">kad:</span> <u>subota, 28.09.24. u 18:00h</u><br>
                <span class="bold-color">gdje:</span> <span style="font-weight:500; color:#f481a6;" @click="openLocationLink()">Square café</span> (ulica Grada Mainza 15)<br>
                <span class="bold-color">dress code:</span> što više roze boje, to bolje :)<br>
                unesi svoje ime i prezime u prazno polje kako bi potvrdila svoj dolazak:<br>
              </p>
              <v-text-field v-if="!nameSent" class="mt-2 thankyou-input" label="Ime i prezime" variant="outlined" v-model="name" hide-details></v-text-field>
              <div v-if="!nameSent" style="display:flex; justify-content: start;">
                <div class="submit-button" @click="submitName">Pošalji</div>
              </div>
              <div class="mt-2" v-if="nameSent" style="display:flex; justify-content: start; color:#f481a6; font-weight:500;">
                Zabilježeno :) Hvala, vidimo se! 
              </div>
              <p>
                veselim se druženju sa svima vama, mojim divnim ženama! 🩷<br>
              </p>
              <p>
                i za kraj... volim vas, cijenim vas, stvarno.<br>
                i hvala vam - još jednom.<br>
                uvijek ♡,<br>
                Jana (Migoto)
              </p>
              <v-img
                eager
                class="gallery-image thankyou-image"
                :src="require(`@/assets/thankyou/8.jpg`)"
              ></v-img>
              <div class="caption-text">2024.</div>
              <br><br>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NavBar from "./NavBar.vue";
import BackToTop from "./BackToTop.vue";
import axios from 'axios';

export default {
  name: "ThankYouPage",
  components: { NavBar, BackToTop },
  data: () => ({
    name: null,
    nameSent: false,
  }),
  methods: {
    scrollToBottom(){
      const el = this.$refs.scrollToMe;
      if (el) {
        var elementPosition = el.getBoundingClientRect().top;
        var offsetPosition = elementPosition - 20;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    },
    openLocationLink(){
      window.open('https://maps.app.goo.gl/XZSpqFVV1inNarSo7');
    },
    openInstagramLink(){
      window.open("https://www.instagram.com/jana__811/");
    },
    async submitName() {
      try {
        const response = await axios.post(
          'https://partihaus.com.hr/api/public/migoto-user',
          { name: this.name },
          {
            headers: {
              'Security-Header': 'securityheaderstring12355',
            },
          }
        );
        this.nameSent = true;
        console.log(response);
      } catch (error) {
        console.error('Error submitting name:', error);
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: "JandysDua";
  src: local("JandysDua"), url(../fonts/Jandys-dua.otf) format("truetype");
}
@font-face {
  font-family: "SouthAustralia";
  src: local("SouthAustralia"),
    url(../fonts/South-Australia.ttf) format("truetype");
}

.bold-color{
  font-weight:bold;
}

.rules-content-container {
  font-size: 18px;
}

.rules-content-container p {
  margin-top: 10px;
}

.rules-list-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  background: url(../assets/background.png);
  background-position: top;
  background-repeat: repeat-y;
}

.rules-global-content-container {
  margin-block: 40px;
  margin-inline: 25% !important;
  font-family: "montserrat";
  flex: unset !important;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.9px);
  -webkit-backdrop-filter: blur(4.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: rgb(102, 102, 102);
  font-size: 24px;
}

.rules-title-name-container {
  font-family: "JandysDua";
  text-align: left;
  font-size: 100px;
  color: #f481a6;
  filter: drop-shadow(0px 0px 5px #ffffff);
}

.bold-entry {
  font-weight: 600;
}

.tldr-link{
  font-weight:500;
  cursor:pointer;
  color:#f481a6;
}

.caption-text{
  width:100%;
  text-align:end;
  margin-bottom:10px;
  font-size:12px;
  font-weight:500;
  color:#f481a6;
}

.thankyou-image{
  margin-top:20px;
}

.submit-button{
  margin-top: 10px;
  background: #f481a6;
  padding-inline: 14px;
  padding-block: 3px;
  color: white;
  border-radius: 5px;
  cursor:pointer;
}

.submit-button:hover{
  background: #dd8fa8;
}

.thankyou-input .v-field__field {
  background:rgba(255, 255, 255, 0.41) !important;
}

@media only screen and (max-width: 991px) {
  .rules-global-content-container {
    margin-inline: 2% !important;
    font-size: 14px;
  }

  .rules-title-name-container {
    font-size: 50px;
  }
}
</style>
